import { MenuDrawer } from '@/scripts/layout/MenuDrawer'
import { trapFocus } from '@/scripts/setup'
import { closestOptional, qsRequired } from '@/scripts/functions'

export class HeaderDrawer extends MenuDrawer {
	static override htmlSelector = 'header-drawer'
	header: HTMLElement
	borderOffset: number
	constructor() {
		super()
		this.header = qsRequired('.section-header')
		this.borderOffset = closestOptional(this, '[data-uc-header-wrapper]')?.classList.contains(
			'header-wrapper--border-bottom'
		)
			? 1
			: 0
	}

	override openMenuDrawer(summaryElement: HTMLElement) {
		document.documentElement.style.setProperty(
			'--header-bottom-position',
			`${this.header.getBoundingClientRect().bottom - this.borderOffset}px`
		)
		this.header.classList.add('menu-open')

		setTimeout(() => {
			this.mainDetailsToggle.classList.add('menu-opening')
		})

		summaryElement.setAttribute('aria-expanded', 'true')
		window.addEventListener('resize', this.onResize)
		trapFocus(this.mainDetailsToggle, summaryElement)
		document.body.classList.add(`overflow-hidden-${this.dataset.breakpoint}`)
	}

	override closeMenuDrawer(elementToFocus?: HTMLElement) {
		super.closeMenuDrawer(elementToFocus)
		this.header.classList.remove('menu-open')
		const summaryEl = qsRequired('summary', this)
		summaryEl.setAttribute('aria-expanded', 'false')
		window.removeEventListener('resize', this.onResize)
	}

	onResize = () => {
		this.header &&
			document.documentElement.style.setProperty(
				'--header-bottom-position',
				`${this.header.getBoundingClientRect().bottom - this.borderOffset}px`
			)
		//document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`)
	}
}
