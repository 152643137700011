import { UcoastEl } from '@/scripts/core/UcoastEl'
import { qsOptional, qsRequired } from '@/scripts/functions'

export class DecoContainer extends UcoastEl {
	static htmlSelector = 'deco-container'
	el: HTMLElement
	deco: HTMLElement
	size: 'lg' | 'sm'
	height: number
	ratio: number
	constructor() {
		super()
		this.el = qsRequired('.deco-container', this)
		this.deco = qsOptional('.deco-lg', this) ?? qsRequired('.deco-sm', this)
		this.size = this.deco.classList.contains('deco-lg') ? 'lg' : 'sm'
		this.height = this.el.getBoundingClientRect().height
		this.ratio = 2.008895283043696
	}
	override connectedCallback() {
		super.connectedCallback()
		this.adjustSplitLineClass()
	}
	private isSingleLineHeight(): boolean {
		const computedStyle = window.getComputedStyle(this.deco)
		const height = this.deco.offsetHeight
		const currentRatio = height / parseInt(computedStyle.fontSize)
		const ratioDiff = currentRatio - this.ratio
		return ratioDiff < 0.1
	}

	private adjustSplitLineClass(): void {
		if (this.isSingleLineHeight()) {
			this.el.classList.remove('deco-container--split-line')
			this.el.classList.remove('deco-container--split-line-mobile')
		} else {
			this.el.classList.add('deco-container--split-line')
			this.el.classList.add('deco-container--split-line-mobile')
		}
	}
}
