import { removeTrapFocus, trapFocus } from '@/scripts/setup'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { closestRequired, qsOptional, qsRequired, targetRequired } from '@/scripts/functions'

export class DetailsModal extends UcoastEl {
	static htmlSelector = 'details-modal'
	detailsContainer: HTMLDetailsElement
	summaryToggle: HTMLElement
	closeButton: HTMLButtonElement
	onBodyClickEvent: ((event: MouseEvent) => void) | undefined
	constructor() {
		super()
		this.detailsContainer = qsRequired('details', this)
		this.summaryToggle = qsRequired('summary', this)
		this.closeButton = qsRequired('button[type="button"]', this)
		this.detailsContainer.addEventListener(
			'keyup',
			(event) => event.code.toUpperCase() === 'ESCAPE' && this.close()
		)
		this.summaryToggle.addEventListener('click', this.onSummaryClick.bind(this))
		this.closeButton.addEventListener('click', this.close.bind(this))
		this.summaryToggle.setAttribute('role', 'button')
		this.onBodyClickEvent = undefined
	}

	isOpen() {
		if (!this.detailsContainer) return false
		return this.detailsContainer.hasAttribute('open')
	}

	onSummaryClick(event: MouseEvent) {
		event.preventDefault()
		const target = targetRequired(event)
		const closest = closestRequired(target, 'details')
		closest.hasAttribute('open') ? this.close() : this.open(event)
	}

	onBodyClick(event: MouseEvent) {
		const target = event.target
		if (!(target instanceof HTMLElement)) return
		if (!this.contains(target) || target.classList.contains('modal-overlay')) this.close(false)
	}

	open(event: Event) {
		this.onBodyClickEvent = this.onBodyClickEvent || this.onBodyClick.bind(this)
		const target = targetRequired(event)
		const closest = closestRequired(target, 'details')
		closest.setAttribute('open', '')
		void closest.offsetWidth; // hack to enforce redraw
		if (this.onBodyClickEvent) {
			document.body.addEventListener('click', this.onBodyClickEvent)
			document.body.classList.add('overflow-hidden')
		}

		const container = qsOptional('[tabindex="-1"]', this.detailsContainer)
		const focusEl = qsOptional('input:not([type="hidden"])', this.detailsContainer)
		if (container) {
			trapFocus(container, focusEl)
		}
	}

	close(focusToggle = true) {
		removeTrapFocus(focusToggle ? this.summaryToggle : undefined)
		this.detailsContainer.removeAttribute('open')
		if (this.onBodyClickEvent) {
			document.body.removeEventListener('click', this.onBodyClickEvent)
			document.body.classList.remove('overflow-hidden')
		}
	}
}
