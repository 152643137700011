import { getAttributeOrThrow, qsOptional, qsRequired } from '@/scripts/functions'
import { type PredictiveSearch } from '@/scripts/forms/predictive-search'
import { type HeaderMenu } from '@/scripts/content/details-disclosure'
import { type DetailsModal } from '@/scripts/content/details-modal'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { setHeightVars } from '@/scripts/essential'
import { type SlideshowComponent } from '@/scripts/media/sliders'

export class StickyHeader extends UcoastEl {
	static htmlSelector = 'sticky-header'
	header!: HTMLElement
	announcement?: HTMLElement
	headerBounds!: {} | DOMRectReadOnly
	currentScrollTop!: number
	predictiveSearch?: PredictiveSearch
	onScrollHandler!: (event: Event) => void
	hideHeaderOnScrollUp!: () => void
	isScrolling: number
	disclosures: [] | NodeListOf<HeaderMenu>
	searchModal?: DetailsModal

	constructor() {
		super()
		this.isScrolling = 0
		this.disclosures = []
	}

	override connectedCallback() {
		super.connectedCallback()
		this.header = qsRequired('.section-header')
		this.announcement = qsOptional('.announcement-bar-section')
		this.headerBounds = new DOMRectReadOnly()
		this.onSetHeightVars()

		window
			.matchMedia('(max-width: 989px)')
			.addEventListener('change', this.onSetHeightVars.bind(this))

		this.header.classList.add('shopify-section-header-sticky')

		this.currentScrollTop = 0
		this.predictiveSearch = qsOptional('predictive-search', this)

		this.onScrollHandler = this.onScroll.bind(this)
		window.addEventListener('scroll', this.onScrollHandler, false)

		const firstSlider = qsOptional<SlideshowComponent>('[data-uc-slider-on-header]')
		if (firstSlider) {
			const firstSlide = qsRequired('[data-uc-slideshow-slide]', firstSlider)
			const activeSlideId = getAttributeOrThrow('id', firstSlide)
			this.header.setAttribute('data-active-slide', activeSlideId)
			this.announcement?.setAttribute('data-active-slide', activeSlideId)
			firstSlider.addEventListener('slideChanged', this.onSlideChanged.bind(this))
		}

		this.createObserver()
	}

	onSlideChanged(event: CustomEvent) {
		if (event.detail.currentElement instanceof HTMLElement) {
			this.header.setAttribute(
				'data-active-slide',
				event.detail.currentElement.getAttribute('id')
			)
			this.announcement?.setAttribute(
				'data-active-slide',
				event.detail.currentElement.getAttribute('id')
			)
		}
	}

	onSetHeightVars() {
		setHeightVars(this.header, this.announcement)
	}

	override disconnectedCallback() {
		super.disconnectedCallback()
		this.removeEventListener('preventHeaderReveal', this.hideHeaderOnScrollUp)
		window.removeEventListener('scroll', this.onScrollHandler)
	}

	createObserver() {
		let observer = new IntersectionObserver((entries, observer) => {
			this.headerBounds = entries[0].intersectionRect
			observer.disconnect()
		})

		observer.observe(this.header)
	}

	onScroll() {
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop
		if (!(this.headerBounds instanceof DOMRectReadOnly)) return
		if (this.predictiveSearch && this.predictiveSearch.isOpen) return
		if (
			this.headerBounds.bottom &&
			scrollTop > this.currentScrollTop &&
			scrollTop > this.headerBounds.top
		) {
			this.header.classList.add('scrolled-past-header')
			this.header.classList.add('has-scrolled')
		} else if (scrollTop < this.currentScrollTop && scrollTop > this.headerBounds.top) {
			this.header.classList.add('scrolled-past-header')
			this.header.classList.add('has-scrolled')
		} else if (scrollTop <= this.headerBounds.top) {
			this.header.classList.remove('scrolled-past-header')
		}

		this.currentScrollTop = scrollTop
	}

	closeMenuDisclosure() {
		this.disclosures = this.disclosures.length
			? this.disclosures
			: this.header.querySelectorAll('header-menu')
		this.disclosures.forEach((disclosure) => disclosure.close())
	}

	closeSearchModal() {
		this.searchModal =
			this.searchModal || qsRequired<DetailsModal>('details-modal', this.header)
		this.searchModal.close(false)
	}
}
