import {
	currentTargetOptional,
	currentTargetRequired,
	qsaOptional,
	qsaRequired,
	qsOptional,
	qsRequired,
	targetOptional,
} from '@/scripts/functions'
import { accessibilityStrings } from '@/scripts/setup'
import { UcoastEl } from '@/scripts/core/UcoastEl'

export class SliderComponent extends UcoastEl {
	static htmlSelector = 'slider-component'
	slider: HTMLElement
	sliderItems: NodeListOf<HTMLElement>
	enableSliderLooping: boolean
	currentPageElement?: HTMLElement
	pageTotalElement?: HTMLElement
	prevButtons?: NodeListOf<HTMLButtonElement>
	nextButtons?: NodeListOf<HTMLButtonElement>
	sliderItemsToShow: HTMLElement[]
	sliderItemOffset: number
	slidesPerPage: number
	totalPages: number
	currentPage: number
	slideScrollPosition: number
	constructor() {
		super()
		this.slider = qsRequired('[id^="Slider-"]', this)
		this.sliderItems = qsaRequired('[id^="Slide-"]', this)
		this.enableSliderLooping = false
		this.currentPageElement = qsOptional('.slider-counter--current', this)
		this.pageTotalElement = qsOptional('.slider-counter--total', this)
		this.prevButtons = qsaOptional<HTMLButtonElement>('button[name="previous"]', this)
		this.nextButtons = qsaOptional<HTMLButtonElement>('button[name="next"]', this)
		this.sliderItemsToShow = []
		this.sliderItemOffset = 0
		this.slidesPerPage = 1
		this.totalPages = 1
		this.currentPage = 1
		this.slideScrollPosition = 0

		if (!this.slider) return
		this.initPages()
		const resizeObserver = new ResizeObserver(() => this.initPages())
		resizeObserver.observe(this.slider)

		this.slider.addEventListener('scroll', this.update.bind(this))
		if (this.prevButtons?.length) {
			for (let i = 0; i < this.prevButtons.length; i++) {
				const button = this.prevButtons[i]
				button.addEventListener('click', this.onButtonClick.bind(this))
			}
		}
		if (this.nextButtons?.length) {
			for (let i = 0; i < this.nextButtons.length; i++) {
				const button = this.nextButtons[i]
				button.addEventListener('click', this.onButtonClick.bind(this))
			}
		}
		this.slider.addEventListener('scroll', this.update.bind(this))
	}

	initPages() {
		this.sliderItemsToShow = Array.from(this.sliderItems).filter(
			(element) => element.clientWidth > 0
		)
		if (this.sliderItemsToShow.length < 2) return
		this.sliderItemOffset =
			this.sliderItemsToShow[1].offsetLeft - this.sliderItemsToShow[0].offsetLeft
		this.slidesPerPage = Math.floor(
			(this.slider.clientWidth - this.sliderItemsToShow[0].offsetLeft) / this.sliderItemOffset
		)
		this.totalPages = this.sliderItemsToShow.length - this.slidesPerPage + 1
		this.update()
	}

	resetPages() {
		this.sliderItems = this.querySelectorAll('[id^="Slide-"]')
		this.initPages()
	}

	update() {
		// Temporarily prevents unneeded updates resulting from variant changes
		// This should be refactored as part of https://github.com/Shopify/dawn/issues/2057
		if (!this.slider) return

		const previousPage = this.currentPage
		this.currentPage = Math.round(this.slider.scrollLeft / this.sliderItemOffset) + 1

		if (this.currentPageElement && this.pageTotalElement) {
			this.currentPageElement.textContent = `${this.currentPage}`
			this.pageTotalElement.textContent = `${this.totalPages}`
		}

		if (this.currentPage != previousPage) {
			this.dispatchEvent(
				new CustomEvent('slideChanged', {
					detail: {
						currentPage: this.currentPage,
						currentElement: this.sliderItemsToShow[this.currentPage - 1],
					},
				})
			)
		}

		if (this.enableSliderLooping) return
		if (this.prevButtons?.length) {
			if (this.isSlideVisible(this.sliderItemsToShow[0]) && this.slider.scrollLeft === 0) {
				this.prevButtons.forEach((button) => {
					button.setAttribute('disabled', 'disabled')
				})
			} else {
				this.prevButtons.forEach((button) => {
					button.removeAttribute('disabled')
				})
			}
		}
		if (this.nextButtons?.length) {
			if (this.isSlideVisible(this.sliderItemsToShow[this.sliderItemsToShow.length - 1])) {
				this.nextButtons.forEach((button) => {
					button.setAttribute('disabled', 'disabled')
				})
			} else {
				this.nextButtons.forEach((button) => {
					button.removeAttribute('disabled')
				})
			}
		}
	}

	isSlideVisible(element: HTMLElement, offset = 0) {
		const lastVisibleSlide = this.slider.clientWidth + this.slider.scrollLeft - offset
		return (
			element.offsetLeft + element.clientWidth <= lastVisibleSlide &&
			element.offsetLeft >= this.slider.scrollLeft
		)
	}

	onButtonClick(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		const currentTarget = currentTargetRequired<MouseEvent | TouchEvent, HTMLButtonElement>(
			event
		)
		const step = currentTarget.dataset.step ? parseInt(currentTarget.dataset.step) : 1
		this.slideScrollPosition =
			currentTarget.name === 'next'
				? this.slider.scrollLeft + step * this.sliderItemOffset
				: this.slider.scrollLeft - step * this.sliderItemOffset
		this.slider.scrollTo({
			left: this.slideScrollPosition,
		})
	}
}

export class SlideshowComponent extends SliderComponent {
	static override htmlSelector = 'slideshow-component'
	sliderControlWrapper?: HTMLElement
	sliderFirstItemNode: HTMLElement
	sliderControlLinksArray?: HTMLElement[]
	sliderAutoplayButton?: HTMLButtonElement
	autoplaySpeed?: number
	autoplayButtonIsSetToPlay: boolean
	sliderControlButtons?: NodeListOf<HTMLButtonElement>
	autoplay?: number

	constructor() {
		super()
		this.autoplayButtonIsSetToPlay = false
		this.enableSliderLooping = true
		this.sliderControlWrapper = qsOptional('[data-uc-slider-controls]', this)

		this.sliderFirstItemNode = qsRequired('[data-uc-slideshow-slide]', this.slider)
		if (this.sliderItemsToShow.length > 0) this.currentPage = 1

		if (this.sliderControlWrapper) {
			this.sliderControlLinksArray = Array.from(
				this.sliderControlWrapper.querySelectorAll('.slider-counter__link')
			)
			this.sliderControlLinksArray.forEach((link) =>
				link.addEventListener('click', this.linkToSlide.bind(this))
			)
		}

		this.slider.addEventListener('scroll', this.setSlideVisibility.bind(this))
		this.setSlideVisibility()

		if (this.slider.getAttribute('data-autoplay') === 'true') this.setAutoPlay()
		if (!this.hasAttribute('data-uc-disable-draggable-slider')) {
			this.initDraggableSlider()
		}
	}

	override connectedCallback() {
		super.connectedCallback()
	}

	setAutoPlay() {
		this.sliderAutoplayButton = qsRequired('[data-uc-slideshow-autoplay]', this)
		const speed = this.slider.dataset.speed
		if (!speed) throw 'speed data attribute missing, setAutoPlay'
		this.autoplaySpeed = parseInt(speed) * 1000

		this.sliderAutoplayButton.addEventListener('click', this.autoPlayToggle.bind(this))
		this.addEventListener('mouseover', this.focusInHandling.bind(this))
		this.addEventListener('mouseleave', this.focusOutHandling.bind(this))
		this.addEventListener('focusin', this.focusInHandling.bind(this))
		this.addEventListener('focusout', this.focusOutHandling.bind(this))

		this.play()
		this.autoplayButtonIsSetToPlay = true
	}

	override onButtonClick(event: MouseEvent | TouchEvent) {
		super.onButtonClick(event)
		const isFirstSlide = this.currentPage === 1
		const isLastSlide = this.currentPage === this.sliderItemsToShow.length

		if (!isFirstSlide && !isLastSlide) return

		const currentTarget = currentTargetOptional<typeof event, HTMLButtonElement>(event)
		if (!currentTarget) return

		if (isFirstSlide && currentTarget.name === 'previous') {
			this.slideScrollPosition =
				this.slider.scrollLeft +
				this.sliderFirstItemNode.clientWidth * this.sliderItemsToShow.length
		} else if (isLastSlide && currentTarget.name === 'next') {
			this.slideScrollPosition = 0
		}
		this.slider.scrollTo({
			left: this.slideScrollPosition,
		})
	}

	override update() {
		super.update()
		this.sliderControlButtons = this.querySelectorAll('.slider-counter__link')
		if (this.prevButtons?.length)
			this.prevButtons.forEach((button) => button.removeAttribute('disabled'))

		if (!this.sliderControlButtons.length) return

		this.sliderControlButtons.forEach((link) => {
			link.classList.remove('slider-counter__link--active')
			link.removeAttribute('aria-current')
		})
		this.sliderControlButtons[this.currentPage - 1].classList.add(
			'slider-counter__link--active'
		)
		this.sliderControlButtons[this.currentPage - 1].setAttribute('aria-current', 'true')
	}

	autoPlayToggle() {
		this.togglePlayButtonState(this.autoplayButtonIsSetToPlay)
		this.autoplayButtonIsSetToPlay ? this.pause() : this.play()
		this.autoplayButtonIsSetToPlay = !this.autoplayButtonIsSetToPlay
	}

	focusOutHandling(event: MouseEvent | TouchEvent) {
		const target = targetOptional<MouseEvent | TouchEvent>(event)
		if (!target) return
		const focusedOnAutoplayButton =
			event.target === this.sliderAutoplayButton ||
			this.sliderAutoplayButton?.contains(target)
		if (!this.autoplayButtonIsSetToPlay || focusedOnAutoplayButton) return
		this.play()
	}

	focusInHandling(event: MouseEvent | TouchEvent) {
		const target = targetOptional<MouseEvent | TouchEvent>(event)
		if (!target) return
		const focusedOnAutoplayButton =
			target === this.sliderAutoplayButton || this.sliderAutoplayButton?.contains(target)
		if (focusedOnAutoplayButton && this.autoplayButtonIsSetToPlay) {
			this.play()
		} else if (this.autoplayButtonIsSetToPlay) {
			this.pause()
		}
	}

	play() {
		this.slider.setAttribute('aria-live', 'off')
		clearInterval(this.autoplay)
		this.autoplay = setInterval(this.autoRotateSlides.bind(this), this.autoplaySpeed)
	}

	pause() {
		this.slider.setAttribute('aria-live', 'polite')
		clearInterval(this.autoplay)
	}

	togglePlayButtonState(pauseAutoplay: boolean) {
		if (!this.sliderAutoplayButton) throw 'this.sliderAutoplayButton not found'
		if (pauseAutoplay) {
			this.sliderAutoplayButton.classList.add('slideshow__autoplay--paused')
			this.sliderAutoplayButton.setAttribute('aria-label', accessibilityStrings.playSlideshow)
		} else {
			this.sliderAutoplayButton.classList.remove('slideshow__autoplay--paused')
			this.sliderAutoplayButton.setAttribute(
				'aria-label',
				accessibilityStrings.pauseSlideshow
			)
		}
	}

	autoRotateSlides() {
		const slideScrollPosition =
			this.currentPage === this.sliderItems.length
				? 0
				: this.slider.scrollLeft +
				  qsRequired('[data-uc-slideshow-slide]', this.slider).clientWidth
		this.slider.scrollTo({
			left: slideScrollPosition,
		})
	}

	setSlideVisibility() {
		this.sliderItemsToShow.forEach((item, index) => {
			const linkElements = item.querySelectorAll('a')
			if (index === this.currentPage - 1) {
				if (linkElements.length)
					linkElements.forEach((button) => {
						button.removeAttribute('tabindex')
					})
				item.setAttribute('aria-hidden', 'false')
				item.removeAttribute('tabindex')
			} else {
				if (linkElements.length)
					linkElements.forEach((button) => {
						button.setAttribute('tabindex', '-1')
					})
				item.setAttribute('aria-hidden', 'true')
				item.setAttribute('tabindex', '-1')
			}
		})
	}

	linkToSlide(event: MouseEvent | TouchEvent) {
		event.preventDefault()
		if (!this.sliderControlLinksArray?.length)
			throw new Error('this.sliderControlLinksArray not deined and tried to link to slide')
		const currentTarget = currentTargetRequired(event)
		const slideScrollPosition =
			this.slider.scrollLeft +
			this.sliderFirstItemNode.clientWidth *
				(this.sliderControlLinksArray.indexOf(currentTarget) + 1 - this.currentPage)
		this.slider.scrollTo({
			left: slideScrollPosition,
		})
	}
	initDraggableSlider() {
		this.slider.addEventListener('mousedown', this.onMouseDown.bind(this))
		//this.slider.addEventListener('touchstart', this.onTouchDown.bind(this), { passive: false })
	}

	onMouseDown(event: MouseEvent) {
		event.preventDefault()

		const clientX = event.clientX
		const initialScroll = this.slider.scrollLeft
		let currentTarget = currentTargetRequired(event)

		const onMouseMove = (e: MouseEvent) => {
			e.preventDefault()
			const currentClientX = e.clientX
			const deltaX = clientX - currentClientX
			this.slider.scrollLeft = initialScroll + deltaX
		}

		const onMouseUp = () => {
			document.removeEventListener('mousemove', onMouseMove)
			document.removeEventListener('mouseup', onMouseUp)

			if (
				-25 < this.slider.scrollLeft - initialScroll &&
				this.slider.scrollLeft - initialScroll < 25 &&
				currentTarget instanceof HTMLAnchorElement
			) {
				currentTarget.click()
			}
		}

		document.addEventListener('mousemove', onMouseMove)
		document.addEventListener('mouseup', onMouseUp)
	}

	/*onTouchDown(event: TouchEvent) {
		//event.preventDefault()

		const clientX = event.touches[0].clientX
		const clientY = event.touches[0].clientY
		//const initialScroll = this.slider.scrollLeft
		let deltaX = 0
		let deltaY = 0
		let currentTarget = targetRequired(event)

		const onTouchMove = (e: TouchEvent) => {
			const currentClientX = e.touches[0].clientX
			const currentClientY = e.touches[0].clientY
			deltaX = clientX - currentClientX
			deltaY = clientY - currentClientY
			// this.slider.scrollLeft = initialScroll + deltaX
		}

		const onTouchUp = (e: Event) => {
			document.removeEventListener('touchmove', onTouchMove)
			document.removeEventListener('touchend', onTouchUp)
			console.log({ deltaX, deltaY })
			if (deltaY < -20 || deltaY > 20) {
				e.preventDefault()
			} else if (
				-25 < deltaX &&
				deltaX < 25 &&
				(currentTarget instanceof HTMLAnchorElement ||
					currentTarget instanceof HTMLButtonElement)
			) {
				//currentTarget.click()
			} else {
				e.preventDefault()
			}
		}

		//document.addEventListener('touchmove', onTouchMove, { passive: false })
		document.addEventListener('touchend', onTouchUp)
	}*/
}
