import { removeTrapFocus, trapFocus } from '@/scripts/setup'
import {
	closestOptional,
	closestRequired,
	currentTargetRequired,
	elementPropertyRequired,
	qsaOptional,
	qsOptional,
	qsRequired,
	targetRequired,
} from '@/scripts/functions'
import { UcoastEl } from '@/scripts/core/UcoastEl'

export class MenuDrawer extends UcoastEl {
	static htmlSelector = 'menu-drawer'
	mainDetailsToggle: HTMLDetailsElement

	constructor() {
		super()
		this.mainDetailsToggle = qsRequired('details', this)
		this.addEventListener('keyup', this.onKeyUp.bind(this))
		this.addEventListener('focusout', this.onFocusOut.bind(this))
		this.bindEvents()
	}

	bindEvents() {
		this.querySelectorAll('summary').forEach((summary) =>
			summary.addEventListener('click', this.onSummaryClick.bind(this))
		)
		this.querySelectorAll('button:not(.localization-selector)').forEach((button) =>
			button.addEventListener('click', this.onCloseButtonClick.bind(this))
		)
	}

	onKeyUp(event: KeyboardEvent) {
		if (event.code.toUpperCase() !== 'ESCAPE') return
		const openDetailsElement = closestRequired(targetRequired(event), 'details[open]')

		openDetailsElement === this.mainDetailsToggle
			? this.closeMenuDrawer(qsOptional('summary', this.mainDetailsToggle))
			: this.closeSubmenu(openDetailsElement)
	}

	onSummaryClick(event: MouseEvent | TouchEvent) {
		const summaryElement = currentTargetRequired(event)
		const detailsElement = elementPropertyRequired(summaryElement, 'parentNode')
		const parentMenuElement = closestOptional(detailsElement, '[data-uc-has-submenu]')
		const isOpen = detailsElement.hasAttribute('open')
		const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')

		function addTrapFocus() {
			const next = elementPropertyRequired(summaryElement, 'nextElementSibling')
			trapFocus(next, qsOptional('button', detailsElement))
			next.removeEventListener('transitionend', addTrapFocus)
		}

		if (detailsElement === this.mainDetailsToggle) {
			if (isOpen) event.preventDefault()
			isOpen ? this.closeMenuDrawer(summaryElement) : this.openMenuDrawer(summaryElement)
			/*
			if (window.matchMedia('(max-width: 989px)')) {
				document.documentElement.style.setProperty(
					'--viewport-height',
					`${window.innerHeight}px`
				)
			}*/
		} else {
			if (isOpen) {
				this.closeSubmenu(detailsElement)
			} else {
				setTimeout(() => {
					detailsElement.classList.add('menu-opening')
					summaryElement.setAttribute('aria-expanded', 'true')
					parentMenuElement && parentMenuElement.setAttribute('data-uc-submenu-open', '')
					!reducedMotion || reducedMotion.matches
						? addTrapFocus()
						: elementPropertyRequired(
								summaryElement,
								'nextElementSibling'
						  ).addEventListener('transitionend', addTrapFocus)
				}, 1)
			}
		}
	}

	openMenuDrawer(summaryElement: HTMLElement) {
		setTimeout(() => {
			this.mainDetailsToggle.classList.add('menu-opening')
		})
		summaryElement.setAttribute('aria-expanded', 'true')
		trapFocus(this.mainDetailsToggle, summaryElement)
		document.body.classList.add(`overflow-hidden-${this.dataset.breakpoint}`)
	}

	closeMenuDrawer(elementToFocus?: HTMLElement) {
		this.mainDetailsToggle.classList.remove('menu-opening')
		this.mainDetailsToggle.removeAttribute('open')
		void this.mainDetailsToggle.offsetWidth // hack to enforce redraw
		const openDetails = qsaOptional<HTMLDetailsElement>('details', this.mainDetailsToggle)
		if (openDetails)
			openDetails.forEach((details) => {
				details.removeAttribute('open')
				details.classList.remove('menu-opening')
			})
		const openSubmenus = qsaOptional('[data-uc-submenu-open]', this.mainDetailsToggle)
		if (openSubmenus)
			openSubmenus.forEach((submenu) => {
				submenu.removeAttribute('data-uc-submenu-open')
			})
		document.body.classList.remove(`overflow-hidden-${this.dataset.breakpoint}`)
		removeTrapFocus(elementToFocus)
		this.closeAnimation(this.mainDetailsToggle)
	}

	onFocusOut() {
		setTimeout(() => {
			if (
				this.mainDetailsToggle.hasAttribute('open') &&
				!this.mainDetailsToggle.contains(document.activeElement)
			)
				this.closeMenuDrawer()
		})
	}

	onCloseButtonClick(event: MouseEvent | TouchEvent) {
		const currentTarget = currentTargetRequired(event)
		if (currentTarget.hasAttribute('data-uc-close-all')) {
			this.closeMenuDrawer()
		} else {
			const detailsElement = closestRequired<HTMLDetailsElement>(
				currentTargetRequired(event),
				'details'
			)
			this.closeSubmenu(detailsElement)
		}
	}

	closeSubmenu(detailsElement: HTMLElement) {
		const parentMenuElement = closestOptional(detailsElement, '[data-uc-submenu-open]')
		parentMenuElement && parentMenuElement.removeAttribute('data-uc-submenu-open')
		detailsElement.classList.remove('menu-opening')
		const summaryEl = qsRequired('summary', detailsElement)
		summaryEl.setAttribute('aria-expanded', 'false')
		removeTrapFocus(summaryEl)
		this.closeAnimation(detailsElement)
	}

	closeAnimation(detailsElement: HTMLElement) {
		let animationStart: DOMHighResTimeStamp

		const handleAnimation = (time: DOMHighResTimeStamp) => {
			if (animationStart === undefined) {
				animationStart = time
			}

			const elapsedTime = time - animationStart

			if (elapsedTime < 400) {
				window.requestAnimationFrame(handleAnimation)
			} else {
				detailsElement.removeAttribute('open')
				const openEl = closestOptional(detailsElement, 'details[open]')
				if (!openEl) return
				trapFocus(openEl, qsOptional('summary', detailsElement))
			}

			window.requestAnimationFrame(handleAnimation)
		}
	}
}
