import { SlideshowComponent } from '@/scripts/media/sliders'
import { type JsonApiResponse, type JsonProduct } from '@/scripts/types/responses'

export class TouchpointSlideshow extends SlideshowComponent {
	static override htmlSelector = 'touchpoint-slideshow'
	productId?: string
	initializedProducts: boolean
	reInitializedSlider: boolean
	relatedProducts: JsonApiResponse
	complementaryProducts: JsonApiResponse
	touchpointProductHandles: string[]
	allProducts: JsonProduct[]
	constructor() {
		super()
		this.initializedProducts = false
		this.reInitializedSlider = false
		this.allProducts = []
		this.relatedProducts = { intent: 'related', products: [] }
		this.complementaryProducts = { intent: 'complementary', products: [] }
		this.touchpointProductHandles =
			this.getAttribute('data-uc-touchpoint-product-handles')?.split(',') ?? []
	}

	reInitialize() {}

	/*loadAsyncProducts() {
		const handleIntersection = async (
			entries: IntersectionObserverEntry[],
			observer: IntersectionObserver
		) => {
			if (!entries[0].isIntersecting) return
			observer.unobserve(this)
			const productId = this.dataset.product
			if (!productId) {
				return
			}
			this.relatedProducts = await fetch(
				routes.root +
				`recommendations/products.json?product_id=${productId}limit=4&intent=related`
			)
				.then((response) => response.json())
				.then(({ products }) => {
					if (products.length > 0) {
						return products
					}
					return []
				})
				.catch((e) => {
					console.log(e, 'could not fetch related products', productId)
				})
			console.log('this.relatedProducts', this.relatedProducts)
			if (this.relatedProducts.products.length >= 4) {
				this.reInitialize()
				return
			}
			this.complementaryProducts = await fetch(
				routes.root +
				`recommendations/products.json?product_id=${productId}limit=4&intent=complementary`
			)
				.then((response) => response.json())
				.then(({ products }) => {
					if (products.length > 0) {
						return products
					}
					return []
				})
				.catch((e) => {
					console.log(e, 'could not fetch related products', productId)
				})

			this.reInitialize()
		}

		new IntersectionObserver(handleIntersection.bind(this), {
			rootMargin: '0px 0px 400px 0px',
		}).observe(this)
	}*/
}
