import { pauseAllMedia } from '@/scripts/setup'
import { UcoastEl } from '@/scripts/core/UcoastEl'
import { qsRequired } from '@/scripts/functions'

export class DeferredMedia extends UcoastEl {
	static htmlSelector = 'deferred-media'
	constructor() {
		super()
		const poster = this.querySelector('[id^="Deferred-Poster-"]')
		if (!poster) return
		poster.addEventListener('click', this.loadContent.bind(this))
	}

	loadContent(focus = true) {
		pauseAllMedia()
		if (!this.getAttribute('loaded')) {
			const content = document.createElement('div')
			const template: HTMLTemplateElement | null = this.querySelector('template')
			if (!template) return
			const firstChildEl = template.content.firstElementChild
			if (!firstChildEl) return
			content.appendChild(firstChildEl.cloneNode(true))

			this.setAttribute('loaded', 'true')
			const deferredElement = this.appendChild(
				qsRequired('video, model-viewer, iframe', content)
			)
			if (focus) deferredElement.focus()
			if (
				deferredElement.nodeName == 'VIDEO' &&
				deferredElement.getAttribute('autoplay') &&
				deferredElement instanceof HTMLVideoElement
			) {
				// force autoplay for safari
				void deferredElement.play()
			}
		}
	}
}
